import * as XLSX from 'xlsx'

export function getXLSXBlob(dataList) {
    const workbook = XLSX.utils.book_new()

    dataList.forEach(({ title, data }) => {
        const worksheet = XLSX.utils.aoa_to_sheet(data.split('\n').map(row => row.split(',')))
        XLSX.utils.book_append_sheet(workbook, worksheet, title)
    })

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' })
    return blob
}