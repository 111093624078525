import moment from "moment"
import { KPI_FILTER_OPTIONS, KPI_RANGE_TYPES } from "../../../constants/types"
import i18next from "i18next"

export function formatJourneyData(data, filterBy) {
    const chartData = [[i18next.t(`commandBox.${filterBy.toLowerCase()}`)]]
    const flatData = Object.values(data).flat()
    const uniqueNumTrips = [...new Set(flatData.map(item => item.numTrips))].sort((a, b) => a - b)

    uniqueNumTrips.forEach(num => {
        chartData[0].push(
            `${i18next.t('commandBox.journeys.totalJourneysChart.label', { count: num })}`
        )
    })

    Object.entries(data).map(([key, value]) => {
        const sortedValues = Object.values(value).sort((a, b) => a.numTrips - b.numTrips)
        const row = [key]

        uniqueNumTrips.forEach(num => {
            const entry = sortedValues.find(el => el.numTrips === num)
            row.push(entry ? entry.rep : 0)
        })
        chartData.push(row)
    })

    return chartData
}


export function orderChartBody(body) {
    if (!body) return

    return body.sort((a, b) => {
        const key1 = a[0]
        const key2 = b[0]

        const month1 = key1.split('-')[0].trim()
        const year1 = parseInt(key1.split('-')[1]?.trim())

        const month2 = key2.split('-')[0].trim()
        const year2 = parseInt(key2.split('-')[1]?.trim())

        if (year1 && year2 && year1 !== year2) {
            return year1 - year2
        }

        return month1 - month2
    })
}

export function getKeyStringByRange(date, range, isDifferentYear) {
    let key

    switch (range) {
        case KPI_RANGE_TYPES.month:
            key = moment(date).format('MM')
            if (isDifferentYear) {
                key += ` - ${moment(date).format('YYYY')}`
            }
            break
        case KPI_RANGE_TYPES.year:
            key = moment(date).format('YYYY')
            break
        default:
            key = moment(date).format('MM')
    }

    return key
}

export function getBodyWithTranslations(body, range) {
    if (!body) return

    if (range === KPI_RANGE_TYPES.year) {
        return body
    }

    return body.map((row) => {
        const parts = row[0].split('-').map(part => part?.trim());
        const months = i18next.t('commandBox.months', { returnObjects: true });
        const translatedMonth = months[parseInt(parts[0]) - 1];
        row[0] = parts.length > 1
            ? `${translatedMonth} - ${parts[1]}`
            : translatedMonth;
        return row;
    })
}

export function fillBodyWithZeros(body, length) {
    if (!body) return

    return body.map((row) => {
        while (row.length < length) {
            row.push(0);
        }
        return row
    });
}

export function getFilterByName(data, filterBy) {
    let filterByName = []
    switch (filterBy) {
        case KPI_FILTER_OPTIONS.services:
            filterByName = data.services
            break
        case KPI_FILTER_OPTIONS.otps:
            filterByName = data.otps
            break
        case KPI_FILTER_OPTIONS.tenant:
            filterByName = data.tenants
            break
        case KPI_FILTER_OPTIONS.region:
            filterByName = data.regions
            break
        case KPI_FILTER_OPTIONS.municipalities:
            filterByName = data.municipalities
            break
        case "driverName":
            filterByName = data.driverName
            break
        case "vehicleDisplayName":
            filterByName = data.vehicleDisplayName
            break
        default:
            break
    }
    return filterByName
}


export function getGroupedTrip(data, origin, destination) {
    const groupedTrip = data.find(
        item => item.origin === origin &&
            item.groupedTrips.some(trip => trip.destination === destination)
    )?.groupedTrips.find(trip => trip.destination === destination);

    return groupedTrip ? groupedTrip.totalTrips : 0;
}


export function groupBy(data, groupedBy, value) {
    const total = [];

    value.forEach(element => {
        let numTotal = 0;

        data.forEach(user => {
            const group = user[groupedBy];
            if (Array.isArray(group)) {
                if (group.includes(element)) numTotal++;
            } else if (group === element) {
                numTotal++;
            }
        });

        if (numTotal > 0 && !total.some(el => el[0]?.v === element)) {
            total.push([
                { v: (value.length < 7 && groupedBy !== 'tenants') ? `${element}\n` : '\n', f: element },
                { v: numTotal, f: numTotal }
            ]);
        }
    });

    return total;
}

export function calculateAVGFromBody(body, numOfDecimals) {
    return body.map(row => {
        return [
            row[0],
            ...row.slice(1).map(elem => {
                if (Array.isArray(elem) && elem.length > 0) {
                    const sum = elem.reduce((acc, curr) => acc + curr, 0);
                    const avg = sum / elem.length;
                    return parseFloat(avg.toFixed(numOfDecimals));
                }
                return 0;
            })
        ];
    });
}
