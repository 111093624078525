import { Grid, Paper, makeStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import TotalTripsByUserChart from "./components/totalTripsByUserChart";
import { TotalCustomersChart } from "./components/totalCustomersChart";

export default function UsersKPI() {
    const classes = useStyles()

    const servicesLoading = useSelector(state => state.serviceReducer.pending)

    return (
        <section className={classes.wrapper}>
            <Grid container spacing={1}>
                <Grid item sm={12}>
                    <Paper className={[classes.paper, classes.paddingGraph]} >
                        <TotalCustomersChart isLoading={servicesLoading} />
                    </Paper>
                </Grid>
                <Grid item sm={12}>
                    <Paper className={[classes.paper, classes.paddingGraph]} >
                        <TotalTripsByUserChart isLoading={servicesLoading} />
                    </Paper>
                </Grid>
            </Grid>
        </section >
    )
}

const useStyles = makeStyles({
    paper: {
        padding: "10px",
        minHeight: "250px"
    },

    paddingGraph: {
        padding: "10px 20px"
    },

    wrapper: {
        display: "grid",
        gap: "5px"
    }
})