import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Chart from "react-google-charts";
import i18next from "i18next";
import { getJourneyKms } from "../../../helpers/kpiHelper";
import KpiWrapper from "../../../components/kpiWrapper";
import PropTypes from 'prop-types'

export default function VehicleDistanceChart({ isLoading }) {
    const { t } = useTranslation()

    const journeys = useSelector(state => state.kpiReducer.journeys)
    const isJourneysLoading = useSelector(state => state.kpiReducer.journeysLoading)

    const { data, total } = useMemo(() => {
        if (!journeys.length) return { data: [] }
        return getJourneyKms(journeys, "vehicleDisplayName")
    }, [journeys])

    isLoading = isLoading || isJourneysLoading
    const displayNoData = !isLoading && (!data || data.length == 0)

    return (
        <KpiWrapper
            title={t("commandBox.vehicles.vehicleDistanceChart.title")}
            displayNoData={displayNoData}
            isLoading={isLoading}
            total={`${total?.toFixed(2)} km`}
            skeletonVariant="rect"
        >
            <Chart
                chartType="ColumnChart"
                graphID={'chart_kms_vehicle'}
                data={data}
                chartLanguage={i18next.language}
            />
        </KpiWrapper>
    )
}

VehicleDistanceChart.propTypes = {
    isLoading: PropTypes.bool
}