import React from "react";
import { useSelector } from "react-redux";
import Chart from "react-google-charts";
import KpiWrapper from "../../../components/kpiWrapper";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { getReservationTimeSlotsChart } from "../../../helpers/kpiHelper";

export default function ReservationTimeSlotsChart({ isLoading }) {
    const { t } = useTranslation()
    const tripBooking = useSelector(state => state.kpiReducer.tripBooking)
    const isTripBookingLoading = useSelector(state => state.kpiReducer.tripBookingLoading)
    const { type } = useSelector(state => state.kpiFiltersReducer)

    const { data } = getReservationTimeSlotsChart({ tripBooking, type })

    const chartOptions = {
        vAxis: { title: t("commandBox.trips.reservationTimeSlotsChart.vAxis") },
        seriesType: "bars",
        isStacked: true,
    };

    isLoading = isLoading || isTripBookingLoading
    const displayNoData = !isLoading && (!tripBooking || tripBooking.length == 0)

    return (
        <KpiWrapper
            title={t("commandBox.trips.timeSlotChart.title")}
            displayNoData={displayNoData}
            isLoading={isLoading}
            skeletonVariant="rect">
            <Chart
                chartType="ComboChart"
                graphID={'chart_reservation_time_slots'}
                data={data}
                options={chartOptions}
                chartLanguage={i18next.language}
            />
        </KpiWrapper>
    )
}